import React from "react";
import style from "./style.module.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SubForm0 = ({
  handleChange,
  shopBaseToppings,
  selectedBaseToppings,
  baseToppingsDescription,
  optionsPaid
}) => {

  return (
    <div className={style.Container}>
      <div className={style.baseToppingsDescription}>{baseToppingsDescription}</div>
      <div className={style.ItemOptionList}>{shopBaseToppings.map((baseTopping, idx) => {
          return (
            <div key={idx} className={style.ItemOption}>
              <div className={style.WrapCheckImg}>
                <input
                  className={style.ItemOptionRadioBtn}
                  type="checkbox"
                  value={baseTopping?.id}
                  name="selectedBaseToppings"
                  checked={selectedBaseToppings.some(t => t.id == baseTopping.id)}
                  onChange={(e)=> handleChange(e)}
                />
                <div className={style.itemImage}>
                    <LazyLoadImage effect='blur'
                                   width="100%"
                                   src={baseTopping?.baseTopping?.image}
                    />
                </div>
              </div>
              <label className={style.ItemOptionName}>{baseTopping.baseTopping.name}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubForm0;
