import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import Modal from 'react-modal';
import bgWelcomeMap from '../../../assets/bgWelcome';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class ChooseOrderTypeModal extends React.Component {
    goMenu = () => {
        const { storeOpened, orderData, setOrderData } = this.props.myShopApiStore;
        const { setActiveTopMenu } = this.props.ifaceMngr;
        const { closeModal } = this.props;

        if(!orderData?.orderType) setOrderData('orderType', 'delivery'); // FOR THE CASE OF OVERLAY CLICK ON THE FIRST LOAD
        
        storeOpened && setActiveTopMenu("ShopMenu");
        closeModal();
    }
    render() {
        const { modalIsOpen, shop, bgColor, t } = this.props;
        const { openStore, setOrderData, shopsData } = this.props.myShopApiStore;
        const types = [{type: 'delivery', label: t('by delivery')}, 
                       {type: 'pickup', label: t('by pickup')}, 
                       {type: 'sit', label: t('by sit')}
                      ].filter(item => shop?.orderTypes.includes(item.type));

        const modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                margin: 0,
                padding: 0,
                background: `url(${bgWelcomeMap[this.props.bgColor]??bgWelcomeMap['#292929']}) no-repeat`,
                backgroundSize: '100%',
                backgroundPosition: 'top',
                backgroundColor: '#FFFF',
                maxHeight: '100%',
                overflow: 'hidden',
                width: '100%',
                maxWidth: '550px',
                paddingTop: '50px',
                paddingBottom: '30px'
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 11
            }
        }

        return (
            <Modal style={modalStyle} isOpen={modalIsOpen} onRequestClose={this.goMenu} ariaHideApp={false}>
                <div className={style.ModalContainer}>
                    <h3>
                        {t('How do you want to take the order')}
                    </h3>
                    {types.map((item, idx) => ( 
                        <button key={idx} 
                                onClick={()=>{
                                    setOrderData('orderType', item.type); 
                                    openStore(shop.id, shop.name, '', { 
                                        preOrderType:item.type,
                                        lng: this.props.ifaceMngr.i18nextLng
                                    }); 
                                    this.goMenu();
                                }} 
                                type="button" style={{color: bgColor, border: `1px solid ${bgColor}`}}>
                                    {item.label}
                        </button>
                    ))}
                    <h4>
                        {t("Please note that the prices and products on the menu may be different according to you choice")}
                    </h4>
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(ChooseOrderTypeModal);