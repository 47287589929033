import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ChangeOrderTypeModal from "../ChangeOrderTypeModal";

//import ChooseOrderType from "./../../../../../StoresScreen/ChooseOrderTypeModal";

import style from "./style.module.css";

const OrderTypeSwitch = ({shop, changeOrderType, thisCart, orderType, mainColor, orderTypesOvertime }) => {
    const [orderTypeModalIsOpen, setOrderTypeModalIsOpen] = useState(false);
    const [tryChangeTypeOrderTo, setTryChangeOrderTo]=useState('');
    const { t } = useTranslation();
    const labels = {delivery: t('delivery'), pickup: t('pickup'), sit: t('inhouse')};

    const firstRadius = document.body.dir === 'rtl' ? '0 50px 50px 0' : '50px 0 0 50px';
    const lastRadius = document.body.dir === 'rtl' ? '50px 0 0 50px' : '0 50px 50px 0';


    const chosenTypeStyle = { backgroundColor: mainColor, color: "#FFF", borderColor: mainColor};
    const typeStyle = { backgroundColor: "#fff", color: mainColor, borderColor: mainColor, cursor: 'pointer'};


    return (
        shop.orderTypes.length > 1 ?
        <div className={style.Container}>  
            <div className={style.BtnGroup}>
                <div className={style.OptionsBtns} style={{}}>
                    {shop.orderTypes.sort().map((item, idx)=> 
                        <button key={idx}
                            onClick={()=>{
                                setTryChangeOrderTo(item);
                                (shop.isPriceOrderType && thisCart.cartItemList.length)
                                    ? setOrderTypeModalIsOpen(true)
                                    : changeOrderType(item);
                                
                            }}
                            style={{...orderType===item?chosenTypeStyle:typeStyle, borderRadius: idx === 0 ? firstRadius : (idx === shop.orderTypes.length-1 ? lastRadius : '0 0 0 0') }}>
                                <div {...(orderTypesOvertime[item]?{title:t('notAvailable')}:{})}>{labels[item]}</div>
                                {/* {orderTypesOvertime[item]?<><div data-tip={'לא זמין כעט'} data-for={`checkedInfo_${item}`} title={'check it out'}>{labels[item]}</div>
                                    <ReactTooltip
                                        className={style.Tooltip}
                                        place="right" 
                                        multiline={true}
                                        data-html={true}
                                        insecure={true} 
                                        id={`checkedInfo_${item}`}
                                        type='light'
                                        border={true}
                                        effect='solid'
                                    /></>:labels[item]} */}
                            </button>)}
                        <ChangeOrderTypeModal modalIsOpen={orderTypeModalIsOpen} 
                                            closeModal={() => setOrderTypeModalIsOpen(false)}
                                            toType={tryChangeTypeOrderTo}
                                            changeOrderType={changeOrderType}
                        
                        />
                </div>
            </div>
        </div> : ''
    )
}

export default OrderTypeSwitch;