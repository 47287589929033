import React, { useMemo } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import style from "./style.module.css";
import CouponModal from "./CouponModal";
import CouponSuccessModal from "./CouponSuccessModal";
import CloseStoreModal from "./CloseStoreModal";
import ProductsAssociatedModal from 'Components/MainScreen/AppBody/ProductsAssociatedModal';
import { IoMdClose } from 'react-icons/io';
import { createPortal } from "react-dom";


const CouponButton = (props) => {
    const { t, coupon, clearCoupon, shopData, isCouponModalOpen, cartPriceBeforeDiscount } = props;
    const couponBtnTitle = useMemo(() => {
        switch(coupon?.type) {
            case 'percent':
                return t('Coupon code has been applied. Your discount is', {coupon: {...coupon, currencyName: '%'}});
            case 'money':
                return cartPriceBeforeDiscount < coupon.discount 
                    ? (t('Coupon code has been applied. Your discount bigger than the cart price. The discount will be applied on the cart price.', 
                        {coupon: {...coupon, cartPrice: cartPriceBeforeDiscount, currencyName: shopData.country?.currencyName ?? '₪'}}) 
                        
                        )
                    : t('Coupon code has been applied. Your discount is', {coupon: {...coupon, cartPrice: cartPriceBeforeDiscount, currencyName: shopData.country?.currencyName ?? '₪'}});
            case 'product':
                const product = props.cartItemList.find(({isCouponProduct}) => isCouponProduct)?.item.product;
                return t('Coupon code for a product has been applied', {product});
            default:
                return t('Do you have coupon code');
        }
    }, [cartPriceBeforeDiscount, coupon, props.cartItemList, shopData.country?.currencyName, t]);

    const delta = coupon?.type === 'money' && coupon?.discount > cartPriceBeforeDiscount ? (coupon?.discount - cartPriceBeforeDiscount).toFixed(2) : 0;
    
    return (
        <div className={style.couponBtn}
            onClick={isCouponModalOpen ? undefined : () => !coupon && props.couponModal(true)}
            {...(coupon ? {style: { backgroundColor: "rgb(217, 217, 217)" }} : {})}
        >  
            {coupon  
                ? couponBtnTitle 
                : <>{t('Do you have coupon code')} <span>{t('ClickHere')}</span></>
            }
            <IoMdClose 
                display={coupon || isCouponModalOpen ? 'unset' : 'none'}
                className={style.CloseBtn} onClick={() => {
                    clearCoupon(coupon?.type !== 'product');  
                    props.couponModal(false) 
                }}
                />
            {!delta || !props.ceveatNode.current || createPortal(
                    t('Please note that the amount of your purchase is lower than the amount of the voucher.') + ' ' + 
                    t('More products must be added or the difference in XX.XX NIS will be reset.', {
                        delta, currencyName: shopData.country?.currencyName ?? '₪'
                    })
                , props.ceveatNode.current)
            }
        </div>
    )
}
@inject((store) => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class PaymentButtons extends React.Component {
    constructor(props) {
        super(props)

        this.couponCeveatRef = React.createRef();
    
        // this.hasCoupon = this.props.myShopApiStore.getCoupon()
        this.state = {
            isCouponModalOpen: props.myShopApiStore.couponPreopened,
            closeStoreModalIsOpen: false,
            successModalIsOpen: false, //coupon success activation
            isMissingProductsModalOpen: false,
            productsAssociatedModalIsOpen: false,
            productsAssociated: null,
            unitTypeSelected: null,
        }
    }

    componentDidUpdate(prevProps) {
        !this.couponCeveatRef.current && (this.couponCeveatRef.current = React.createRef());
        // prevProps.props.myShopApiStore.couponPreopened !== this.props.myShopApiStore.couponPreopened && this.setState({isCouponModalOpen: true});   
    }

    openProductsAssociatedModal = async () => {
        const {setAssociatedStage} = this.props.myShopApiStore;
        setAssociatedStage(true);
    }
    closeProductsAssociatedModal = () => {
        const { orderData, resetOrderData, setAssociatedStage, isPackItemUpdating,
                isCartItemUpdating,  } = this.props.myShopApiStore;

        setAssociatedStage(false);
        !orderData && resetOrderData(); //clean order data details
        !isPackItemUpdating && !isCartItemUpdating &&
                 this.openPaymentPage();
    }

    openCloseStoreModal = () => { 
        this.setState({closeStoreModalIsOpen: true})
    }

    closeCloseStoreModal = () => { 
        this.setState({closeStoreModalIsOpen: false})
    }

    openCouponModal = () => {
        this.setState({isCouponModalOpen: true})
    }

    closeCouponModal = (isSuccess) => {
        //console.log('closeCouponModal');
        this.setState({isCouponModalOpen: false})

        this.props.myShopApiStore.setCouponPreopened(false);

        if(isSuccess){
            this.setState({successModalIsOpen: true})
        }
    }

    precloseCouponModal = () => {
        this.props.myShopApiStore.setCouponPreopened(false);
    }

    openPaymentPage = () => {
        this.setState({inAssociatedProducts: false});
        const { setActiveTopMenu } = this.props.ifaceMngr;
        setActiveTopMenu("Payment")
    }

    handleGoToPaymentClick = async () => {
        const { resetOrderData, orderData, shop, setSearchValue, checkAssociatedProductsInCart ,getProductsAssociated, checkCartItemPresence } = this.props.myShopApiStore;
        const { orderTypes } = shop;
        const { openMissingProductsModal } = this.props;

        const hasDeliveryOp = orderTypes ? orderTypes.some(ot => ot === 'delivery') : true;
        const hasPickupOp = orderTypes ? orderTypes.some(ot => ot === 'pickup') : true;
        const hasSitOp = orderTypes ? orderTypes.some(ot => ot === 'sit') : true;

        setSearchValue('', true);

        //  create a variable to hold the associated products
        let shopProductsAssociatedIds = checkAssociatedProductsInCart();

        if (shopProductsAssociatedIds.length) {
            await getProductsAssociated();
            //  open the modal
            this.openProductsAssociatedModal();
        }else {
            if(!hasDeliveryOp && !hasPickupOp && !hasSitOp){
                this.openCloseStoreModal();
                return;
            }
            if(!await checkCartItemPresence()) {
                openMissingProductsModal();
                return;
            }
            (!shop.isPriceOrderType) /*&& !orderData*/ && resetOrderData(); //clean order data details
            this.openPaymentPage();
        }
    }

    setUnitTypeSelected = (unitType) => {
        this.setState({unitTypeSelected: unitType})
    }

    //  handle Add associated products
    handleAddAssociated = async (item = null) => {
        const {addToCart, isPackItemUpdating,
            isCartItemUpdating} = this.props.myShopApiStore;
        
        let isDuringOrder = isPackItemUpdating || isCartItemUpdating;
        if(!isDuringOrder){
            addToCart(item, item.unitTypes[0]);            
        }
        
        this.closeProductsAssociatedModal();
    }

   

    render() {
        const { mainColor, shop, inAssociatedStage, associatedProducts, coupons, setCoupon, shopId, cartPrice, cartPriceBeforeDiscount, shopData, thisCart} = this.props.myShopApiStore;
        const { t } = this.props;
        //const coupon = coupons.find(({id}) => id === shopId);
        //const delta = coupon?.type === 'money' && coupon?.discount > cartPriceBeforeDiscount ? (coupon?.discount - cartPriceBeforeDiscount).toFixed(2) : 0;
        return (
            <div className={style.Container}>
                <CouponSuccessModal closeModal={() => this.setState({successModalIsOpen: false})} modalIsOpen={this.state.successModalIsOpen}/>
                <CloseStoreModal closeModal={this.closeCloseStoreModal} modalIsOpen={this.state.closeStoreModalIsOpen}/>
                <ProductsAssociatedModal modalIsOpen={inAssociatedStage}
                        closeModal={this.closeProductsAssociatedModal}
                        itemList={associatedProducts}
                        handleAddClick={this.handleAddAssociated}
                    />

                <CouponModal modalIsOpen={this.state.isCouponModalOpen}
                    // display={(this.state.isCouponModalOpen || this.props.myShopApiStore.couponPreopened)? 'block' : 'none'}
                        precloseCouponModal={this.precloseCouponModal} closeModal={this.closeCouponModal}/> 
                                
                <div className={style.PaymentBtns}>
                {
                    shop.isBusiness || !shop.withCoupons
                    ?
                        <div style={{height: "25px", cursor: 'unset'}}/>
                    :   <CouponButton 
                            shopData={shopData} t={t} 
                            coupon={coupons.find(({id}) => id === shopId)} 
                            clearCoupon={this.props.myShopApiStore.clearCoupon}
                            couponModal={(val) => this.setState({isCouponModalOpen: val})}
                            cartPriceBeforeDiscount={cartPriceBeforeDiscount}
                            isCouponModalOpen={this.state.isCouponModalOpen}
                            cartItemList={thisCart.cartItemList}
                            ceveatNode={this.couponCeveatRef}
                            />
                }
               <article ref={this.couponCeveatRef}></article>
                {
                    this.props.hasProducts && 
                    <>
                        <div className={style.goToPayment} onClick={this.handleGoToPaymentClick} disabled={this.state.isCouponModalOpen} style={{ backgroundColor: mainColor, }}>
                            { t(shop.withoutPrices || cartPrice == 0 ? 'continue' : 'Go to payment')}
                        </div>
                        <div style={{ whiteSpace: 'normal'}}>
                            { t('The delivery cost will be determined after entering the address.') }
                        </div>
                    </>
                }
                </div>
                
                
            </div>
        )
    }
}

export default withTranslation()(PaymentButtons);
