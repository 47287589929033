import React from 'react';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import { withTranslation } from 'react-i18next';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import Item from './Item';
import Spinner from 'Components/Spinner';
import CategoryTime from './CategoryTime';
import WorkingTimeSign from './WorkingTimeSign';

const translate = (obj, lng) => { 
    if (!obj) return;
    obj.i18n && Object.keys(obj.i18n).forEach(k => obj.i18n[k][lng] && (obj[k] = obj.i18n[k][lng]));
    obj.product && translate(obj.product, lng)
    obj.levels?.forEach(l => translate(l, lng))
    obj.products?.forEach(p => translate(p, lng))
    obj.shopBaseToppings?.forEach(({baseTopping}) => translate(baseTopping, lng)); 
    obj.shopToppings?.forEach(({topping}) => translate(topping, lng)); 
    obj.shopProductOptions?.forEach(({productOption})=> translate(productOption, lng));
}

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class ItemList extends React.Component {
    constructor(props) {
        super(props)
        this.containerRef = React.createRef()
        this.state = { workingTimePanel: null, prevCategory: null }
    }
    

    //////////////yaroslav code-----------------
    // componentDidMount = () => {
    //     window.addEventListener('resize', this.checkLoader);
    // }

    // componentWillUnmount = () => {
    //     window.removeEventListener('resize', this.checkLoader);
    // }
    /////////////////-------------------------------

    componentDidMount() {
        const { setSearchValue, setSubcatItemList } = this.props.myShopApiStore;
        setSubcatItemList(null);

        //setSearchValue('', true);
    }

    componentDidUpdate(prevProps) {
        const { selectedCategoryCode } = this.props.myShopApiStore;
        // to the top of the list when category is changed
        if (this.state.prevCategory !== selectedCategoryCode) {
            this.containerRef.current.scrollTop = 0;
            this.setState({ prevCategory: selectedCategoryCode });
        }

    }

    // componentWillUnmount = () => {
    //     const { setSubcatItemList } = this.props.myShopApiStore;
    //     setSubcatItemList(null);
    // }

    checkLoader = (e) => {
        if (this.props.myShopApiStore.categoryPageCount > 0 && this.props.myShopApiStore.categoryPageCount > this.props.myShopApiStore.categoryPage)
        {
            const scrollTop = this.containerRef.current.scrollTop;
            const clientHeight = this.containerRef.current.clientHeight;
            if(clientHeight - scrollTop < 300) {
                this.props.myShopApiStore.getItemList(this.props.myShopApiStore.selectedCategoryIdx, false, this.props.myShopApiStore.selectedSubCategoryCodename);
            }
        }
    }

    render() {
        const { itemListAll, suggestions, searchValue, categoryPageCount, subcatItemList, selectedCategoryCode, categories, categoryPage, isProductsLoading, isSearchingProducts, shop, shopId, getCoupon, mainColor, selCategory, hasCategoryTimeLimit, shopData: {country}
        } = this.props.myShopApiStore;

        const { t } = this.props;
        const loading = (
            (!searchValue && ((categoryPageCount > 0 && categoryPageCount > categoryPage) || isProductsLoading))) || 
            (searchValue && isSearchingProducts);
           
        const  itemList2Show = searchValue ? suggestions : (
            (!categories || categories.length === 0 || !itemListAll[shopId]) ? [] : (
                itemListAll[shopId][selectedCategoryCode] ?? subcatItemList
            )
        );
        
        return (
            <div className={style.Container} ref={this.containerRef} onScroll={this.checkLoader}>
                <div className={style.TopDiv}>
                    <WorkingTimeSign shop={ shop } country={ country } />       
                
                    { hasCategoryTimeLimit ? <CategoryTime category = { selCategory } mainColor = { mainColor }/>:null}
                    { itemList2Show?.map((item, index) => {
                            this.props.myShopApiStore.setMaxSideMenuIdx(index);
                            const tItem = toJS(item);
                            translate(tItem, this.props.ifaceMngr.i18nextLng);
                            return (
                                <Item item={tItem} index={index} key={item.id} toggleBasket={this.props.toggleBasket}/>
                            )
                        })   
                    }
                    
                    {
                        !!searchValue && !suggestions.length && !isSearchingProducts &&
                            <div className={style.Oops}>
                                <div className={style.title}>{t("Oops")}...</div>
                                <div className={style.subtitle}>{t("No results were found for the search term you entered")}</div>
                                <div className={style.subtitle}>{t('Try expanding the item description')}</div>
                            </div>
                    }

                    <div className={loading ? style.LoaderContainer : ''}>
                        <Spinner loader='BeatLoader' loading={loading} color={this.props.myShopApiStore.mainColor}/>
                    </div>
                </div>
                {
                    !shop.isBusiness && shop.withCoupons && !getCoupon() &&
                        <div className={style.couponMobile} 
                            onClick={() => {this.props.toggleBasket(); this.props.myShopApiStore.setCouponPreopened(true)}}>
                            {t("Have a coupon?")}
                        </div>
                }
            </div>
        )
    }
}

export default withTranslation()(ItemList);